import React, { useRef, useState } from 'react';
import FeMainContainer, { FeMainContainerRef } from './FeMainContainer';
import FileManagement from './FileManagement';

const CgrMainWrapper: React.FC = () => {
  const mainContainerRef = useRef<FeMainContainerRef>(null);
  const [visibleFileKey, setVisibleFileKey] = useState<string | undefined>();

  // Handle file selection and scroll to the corresponding section
  const handleFileSelect = (selectedKeys: string[]) => {
    if (selectedKeys.length > 0) {
      console.log(selectedKeys)
      const fileKey = selectedKeys[0] as string;
      mainContainerRef.current?.scrollToFile(fileKey);
    }
  };

  // Handle scroll event from FeMainContainer to select the file in FileManagement
  const handleScrollToFile = (fileKey: string) => {
    console.log('Scrolling to file:', fileKey);
    setVisibleFileKey(fileKey); // Update the visible file key
  };

  return (
    <div className="cgr_colabClone">
      <FileManagement onSelect={handleFileSelect} visibleFileKey={visibleFileKey} />
      <FeMainContainer ref={mainContainerRef} onScrollToFile={handleScrollToFile} />
    </div>
  );
};

export default CgrMainWrapper;
