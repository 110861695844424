import { create } from "zustand"
interface IModalData {
  shouldShow: boolean;
  modalHeader?: React.JSX.Element;
  modalBody?: React.JSX.Element;
  modalFooter?: React.JSX.Element;
}
export const MockupStore = create((set) => ({
  projectPageLists: {},
  activePageId: 0,
  requirementApiStatus: false,
  modalState: null,
  cgr_feCode:[],
  isStreaming:false,

  setProjectPageLists: (projectListValue: any) => {
    console.log(projectListValue)
    set({ projectPageLists: projectListValue })
  },
  setActivePageId: (pageIdValue: number) => {
    set({ activePageId: pageIdValue })
  },
  setRequirementApiStatus: (value: boolean) => {
    set({ requirementApiStatus: value })
  },

  setModalState: (value: IModalData) => set({ modalState: value }),
  setCgr_FeCode:(value:any)=>{console.log(value); set({cgr_feCode:value})},
  setIsStreaming:(value:boolean)=>{(set({isStreaming:value}))},
  highlightedLines: [],
  updateHighlightedLines: (currentLine: number) => {
    set((state:any) => {
      const linesToHighlight = [
        Math.max(currentLine - 2, 0), // Two previous lines
        Math.max(currentLine - 1, 0), // One previous line
        currentLine, // Current line
      ];
      return { highlightedLines: linesToHighlight };
    });
  },
}))