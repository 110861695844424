import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

interface CodePreviewCardProps {
  code: string;
  title: string;
}

const CodePreviewCard: React.FC<CodePreviewCardProps> = ({ code, title }) => {
  const outputRef = useRef<any>(null);

  useEffect(() => {
    if (outputRef.current) {
      const allChildren = outputRef.current.children;
      const lastChild = allChildren[allChildren.length - 1]; // Get the last child element

      if (lastChild) {
        // Apply complex animation with background color, scale, and opacity
        (lastChild as HTMLElement).style.transition = 'all 1s ease-in-out';
        (lastChild as HTMLElement).style.backgroundColor = '#EBCBFB'; // Light blue background color
        (lastChild as HTMLElement).style.opacity = '1'; // Start with full opacity
        (lastChild as HTMLElement).style.transform = 'scale(1.05)'; // Slightly enlarge the element

        // Remove the effect after 1 second with animation
        setTimeout(() => {
          (lastChild as HTMLElement).style.backgroundColor = 'transparent';
          (lastChild as HTMLElement).style.opacity = '1'; // Fade opacity
          (lastChild as HTMLElement).style.transform = 'scale(1)'; // Return to normal size
        }, 500);
      }
    }
  }, [code]);

  return (
    <div className="cgr_card">
      <h3 className="cgr_title">{title}</h3>
      
      <div className="cgr_codeLine" ref={outputRef}>
        <ReactMarkdown>{code}</ReactMarkdown>
      </div>
    </div>
  );
};

export default CodePreviewCard;
