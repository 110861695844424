import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import type { DataNode } from 'antd/es/tree';
import DirectoryTree from 'antd/es/tree/DirectoryTree';
import mockupGeneratorService from '../../../services/mockupGenerator.service';
import { MockupStore } from '../../store/Mockup.store';
import { useParams } from 'react-router-dom';

interface FileManagementProps {
  onSelect: (selectedKeys:string[]) => void;
  visibleFileKey?: string; 
}

const FileManagement: React.FC<FileManagementProps> = ({ onSelect, visibleFileKey }) => {
  const { setCgr_FeCode, cgr_feCode, setIsStreaming, updateHighlightedLines } = MockupStore((state: any) => state);
  const [treeView, setTreeView] = useState<DataNode[]>([]);
  const { projectId, threadId, framework } = useParams();
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const parseTreeData = (node: any): DataNode => {
    return {
      title: node.title,
      key: node.key,
      isLeaf: node.type === 'file',
      children: node.children ? node.children.map(parseTreeData) : [],
    };
  };

  const getFileStructure = async () => {
    try {
      const payload = {
        thread_id: threadId,
        project_id: projectId,
        tree_view_for: "frontend"
      };
      const response = await mockupGeneratorService.cgr_getTreeView(payload);

      if (response.data.project_data.frontend_tree_view) {
        const treeData = response.data.project_data.frontend_tree_view;
        const parsedTreeData = [parseTreeData(treeData)];
        setTreeView(parsedTreeData);
      } else {
        console.error("No tree view data available");
      }
    } catch (error) {
      console.error("Error fetching file structure:", error);
    }
  };

  useEffect(() => {
    getFileStructure();
  }, []);

  const generateFeCodeWithFrameWork = async (filePath: string, pageTitle: string) => {
    try {
      setIsStreaming(true);
      let currentLine = 0;
      const payload = {
        page_path: filePath,
        page_title: pageTitle,
        frontend_page_id: 0,
        thread_id: threadId,
        project_id: projectId,
        language_name: "Typescript",
        frontend_framework: "React",
      };

      let codeChunks = "";
      const response: any = await mockupGeneratorService.generateFeCode(payload);
      const reader: ReadableStreamDefaultReader<Uint8Array> | undefined = response.body.getReader();

      while (true) {
        const result: any = await reader?.read();
        if (result) {
          const { done, value } = result;
          if (done) {
            break;
          }

          codeChunks += new TextDecoder().decode(value);

          const updatedCode = cgr_feCode.map((e: any) => {
            if (e.fileName === pageTitle) {
              return { ...e, code: codeChunks };
            }
            return e;
          });

          updateHighlightedLines(currentLine);
          currentLine++;
          setCgr_FeCode(updatedCode);
        }
      }
    } catch (error) {
      console.error("Error generating FE code:", error);
    } finally {
      setIsStreaming(false);
    }
  };

  const handleSelect = async (selectedKeys: React.Key[], info: any) => {
    const { node } = info;
    if (node.isLeaf) {
      console.log('Selected file:', node.title);
      console.log('Selected file path:', node.key);

      // Check if the file code is already generated
      const existingCode = cgr_feCode.find((file: any) => file.fileName === node.title);
      if (existingCode) {
        // If the file code is already generated, just update the selection
        onSelect( [node.key]);
        return;
      }

      // If the file code is not generated yet, proceed with code generation
      try {
        const payload = {
          project_id: projectId,
          thread_id: threadId,
          tree_view_for: "frontend",
          page_title: node.title,
        };

        const response = await mockupGeneratorService.cgr_extractPath(payload);
        cgr_feCode.push({ fileName: node.title, code: "",key:node.key });
        setCgr_FeCode(cgr_feCode);
        await generateFeCodeWithFrameWork(response.data, node.title);
      } catch (error) {
        console.error("Error extracting file path:", error);
      }
    }
  };

  useEffect(() => {
    if (visibleFileKey) {
      setSelectedKeys([visibleFileKey]); // Set the selected key
    }
  }, [visibleFileKey]);
console.log(selectedKeys,treeView)
  return (
    <aside className="cgr_fileStructure">
      <DirectoryTree
        multiple
        showLine
        defaultExpandAll
        treeData={treeView}
        onSelect={handleSelect}
        selectedKeys={selectedKeys}
      />
    </aside>
  );
};

export default FileManagement;
