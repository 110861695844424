import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import CodePreviewCard from './CodePreviewCard';
import { MockupStore } from '../../store/Mockup.store';

export interface FeMainContainerRef {
  scrollToFile: (fileKey: string) => void;
}

interface FeMainContainerProps {
  onScrollToFile: (fileKey: string) => void; // Callback to notify which file is visible
}

const FeMainContainer = forwardRef<FeMainContainerRef, FeMainContainerProps>(({ onScrollToFile }, ref) => {
  const { cgr_feCode } = MockupStore((state: any) => state);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const cellRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useImperativeHandle(ref, () => ({
    scrollToFile: (fileKey: string) => {
      const cellElement = cellRefs.current[fileKey];
      if (cellElement && containerRef.current) {
        containerRef.current.scrollTo({
          top: cellElement.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }));

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [cgr_feCode]);

  // Use intersection observer to detect which section is visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const visibleFile = Object.keys(cellRefs.current).find(
              (key) => cellRefs.current[key] === entry.target
            );
            if (visibleFile) {
              onScrollToFile(visibleFile);                                                                                                                                   
            }
          }
        });
      },
      { root: containerRef.current, threshold: 0.7 } 
    );

    // Observe all cells
    Object.values(cellRefs.current).forEach((cell) => {
      if (cell) observer.observe(cell);
    });

    // Cleanup observer on unmount
    return () => {
      Object.values(cellRefs.current).forEach((cell) => {
        if (cell) observer.unobserve(cell);
      });
    };
  }, [cgr_feCode]);

  const handleScroll = () => {
    if (!containerRef.current) return;

    const containerTop = containerRef.current.scrollTop;
    const containerHeight = containerRef.current.clientHeight;

    let visibleFile: string | null = null;

    Object.entries(cellRefs.current).forEach(([fileKey, cellElement]) => {
      if (cellElement) {
        const cellTop = cellElement.offsetTop - containerTop;
        const cellBottom = cellTop + cellElement.offsetHeight;

        // Check if the cell is within the visible viewport of the container
        if (cellTop < containerHeight && cellBottom > 0) {
          visibleFile = fileKey;
        }
      }
    });

    // Call the callback with the visible fileKey
    if (visibleFile) {
      onScrollToFile(visibleFile);
    }
  };

  return (
    <div className="cgr_mainContent"  onScroll={handleScroll} ref={containerRef} >
      <header className="cgr_colabHeader">
        <button
          className="cgr_addCellButton"
          onClick={() => {
          }}
        >
          + Code
        </button>
      </header>
      <div className="cgr_cellsContainer">
        {cgr_feCode?.map((cell: any) => (
          <div key={cell.fileName} ref={(el) => (cellRefs.current[cell.key] = el)}>
            <CodePreviewCard code={cell.code} title={`${cell.fileName}`} />
          </div>
        ))}
      </div>
    </div>
  );
});

export default FeMainContainer;
